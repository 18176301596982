import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../index.css";
function Services() {
  const screenWidth = window.innerWidth;
  const breakpointWidth = 1020;

  const sbreakpoint = 1440;
    // first if for screewidth upto 1020

  if (screenWidth < breakpointWidth) {
    return (
      <>
        <section className="sersection">
          <div className="services">
            <div className="service-box">
              <h1 className="services-text">Our Services</h1>
              <span className="services-span">
                We are committed to presenting the best services to our clients
                to enhances their travel experience and equip them with
                unforgettable journeys.
              </span>
            </div>
            <div className="services-grid">
              <div className="grid-item airgrid">
                <img
                  src="/Media/Air-Ticket-Consolidation.jpg"
                  alt="airticket-consolidation"
                  className="servicesimg1"
                />
                <div className="box-text">
                  <h4 className="item-text">Air Ticket Consolidation</h4>
                  <p className="item-p">
                    We pride ouselves in providing the best offers, affordable
                    flight bookings and seamless experience to both the agents
                    and our valuable clients. Our team maps out the best travel
                    plan - from homebound adventures to international tours.
                  </p>
                </div>
              </div>
              <div className="grid-item">
                <img
                  src="/Media/personalised-map.jpg"
                  alt="personalised-map"
                  className="servicesimg2"
                />
                <div className="box-text">
                  <h4 className="item-text">Personalised Travel Packages</h4>
                  <p className="item-p">
                    We guarantee exceptional, tailor-made packages that meet
                    your needs. We understand that each traveller is unique, and
                    thus we design every package in accordance with the client's
                    preference and budget.
                  </p>
                </div>
              </div>
              <div className="grid-item airgrid">
                <img
                  src="/Media/Hassle-free.jpg"
                  alt="hasslefree-services"
                  className="servicesimg3"
                />
                <div className="box-text">
                  <h4 className="item-text">Hassle-Free Bookings</h4>
                  <p className="item-p">
                    Our efficient team has mastered the art of taking your share
                    of the burden. From securing your tickets to ensuring
                    seamless acommmodation and other travel necessities- a call
                    to agencies to avail best discount offers for your clients!
                  </p>
                </div>
              </div>
              <div className="grid-item">
                <img
                  src="/Media/24-7.jpg"
                  alt="customer-support"
                  className="servicesimg4"
                />
                <div className="box-text">
                  <h4 className="item-text">24/7 Customer Support</h4>
                  <p className="item-p">
                    Travel hiccups can occur anytime. Our committed customer
                    support team is available around the clock, ready to provide
                    assistance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sservices">
          <div className="sservice-box">
            <h1  className="sservices-text">
              Why <br />
              Choose Us?
            </h1>
            <span className="sservices-span">
              <b>RNR TravelTech</b> understands your need for extraordinary
              travel experience, our dedication, meticulous planning and
              professional guidance sets us apart.
            </span>
          </div>
          <div className="sservices-grid">
            {/* 1st grid */}
            <div className="sgrid-item">
              <div className="sgridimg">
                <img
                  src="/Media/expertise.jpg"
                  alt="airticket-consolidation"
                  className="sservicesimg"
                />
              </div>
              <div className="sbox-text sbox1">
                <img
                  src="/Media/number1.jpg"
                  alt="expertise"
                  className="ccustomer1"
                />

                <h4 className="sitem-text">Expertise</h4>
                <p className="sitem-p">
                  With decades of experience and a proven industry record, we
                  have developed an in-depth knowledge of destinations, airlines
                  and travel styles.
                </p>
              </div>
            </div>
            {/* 2nd grid */}

            <div className="sgrid-item">
              <div className="sgridimg">
                <img
                  src="/Media/save-money.jpeg"
                  alt="hasslefree-services"
                  className="sservicesimg1"
                />
              </div>
              <div className="sbox-text sbox1">
                <img
                  src="/Media/cashhand.png"
                  alt="cash"
                  className="ccustomer1"
                />
                <h4 className="sitem-text ccustomertext1">Affordability</h4>
                <p className="sitem-p">
                  Affordability without compromising quality is our USP! Our
                  strength lies in securing the best deals and helping you save
                  time and expenses.
                </p>
              </div>
            </div>
            {/* 3rd grid */}

            <div className="sgrid-item">
              <div className="sgridimg">
                <img
                  src="/Media/customer-satisfaction.jpg"
                  alt="customer-support"
                  className="sservicesimg threes"
                />
              </div>
              <div className="sbox-text sbox1">
                <img
                  src="/Media/customer.jpg"
                  alt="customersatisfy"
                  className="ccustomer2"
                />

                <h4 className="sitem-text ccustomertext2">
                  Customer - Centric
                </h4>
                <p className="sitem-p">
                  We plan journeys that only satisfy but leave you eager for
                  more. We are ready to go that extra mile in understanding your
                  interests and needs for itineraries matching your unique
                  aspirations.
                </p>
              </div>
            </div>
          </div>
          <h1 className="endingtrust">
            We are passionate about your travel as you are!
          </h1>
        </section>
      </>
    );
  }

// second if for screenwidth from 1020 to 1440

  if(breakpointWidth < sbreakpoint){
    return (
      <>
        <section className="sersection">
          <div className="services">
            <div className="service-box">
              <h1 className="services-text">Our Services</h1>
              <span className="services-span">
                We are committed to presenting the best services to our clients
                to enhances their travel experience and equip them with
                unforgettable journeys.
              </span>
            </div>
            <div className="services-grid">
              <div className="grid-item">
                <img
                  src="/Media/Air-Ticket-Consolidation.jpg"
                  alt="airticket-consolidation"
                  className="servicesimg1"
                />
                <div className="box-text">
                  <h4 className="item-text">Air Ticket Consolidation</h4>
                  <p className="item-p">
                    We pride ouselves in providing the best offers, affordable
                    flight bookings and seamless experience to both the agents
                    and our valuable clients. Our team maps out the best travel
                    plan - from homebound adventures to international tours.
                  </p>
                </div>
              </div>
              <div className="grid-item">
                <img
                  src="/Media/personalised-map.jpg"
                  alt="personalised-map"
                  className="servicesimg2"
                />
                <div className="box-text">
                  <h4 className="item-text">Personalised Travel Packages</h4>
                  <p className="item-p">
                    We guarantee exceptional, tailor-made packages that meet
                    your needs. We understand that each traveller is unique, and
                    thus we design every package in accordance with the client's
                    preference and budget.
                  </p>
                </div>
              </div>
              <div className="grid-item">
                <img
                  src="/Media/Hassle-free.jpg"
                  alt="hasslefree-services"
                  className="servicesimg3"
                />
                <div className="box-text">
                  <h4 className="item-text">Hassle-Free Bookings</h4>
                  <p className="item-p">
                    Our efficient team has mastered the art of taking your share
                    of the burden. From securing your tickets to ensuring
                    seamless acommmodation and other travel necessities- a call
                    to agencies to avail best discount offers for your clients!
                  </p>
                </div>
              </div>
              <div className="grid-item">
                <img
                  src="/Media/24-7.jpg"
                  alt="customer-support"
                  className="servicesimg4"
                />
                <div className="box-text">
                  <h4 className="item-text">24/7 Customer Support</h4>
                  <p className="item-p">
                    Travel hiccups can occur anytime. Our committed customer
                    support team is available around the clock, ready to provide
                    assistance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sservices">
          <div className="sservice-box">
            <h1 className="sservices-text">Why Choose Us?</h1>
            <span className="sservices-span">
              <b>RNR TravelTech</b> understands your need for extraordinary
              travel experience, our dedication, meticulous planning and
              professional guidance sets us apart.
            </span>
          </div>
          <div className="sservices-grid">
            <div className="sgrid-item">
              <img
                src="/Media/expertise.jpg"
                alt="airticket-consolidation"
                className="sservicesimg"
              />
              <div className="sbox-text">
                <img
                  src="/Media/number1.jpg"
                  alt="expertise"
                  className="ccustomer1"
                />

                <h4 className="sitem-text">Expertise</h4>
                <p className="sitem-p">
                  With decades of experience and a proven industry record, we
                  have developed an in-depth knowledge of destinations, airlines
                  and travel styles.
                </p>
              </div>
            </div>

            <div className="sgrid-item">
              <img
                src="/Media/save-money.jpeg"
                alt="hasslefree-services"
                className="sservicesimg"
              />
              <div className="sbox-text">
                <img
                  src="/Media/cashhand.png"
                  alt="cash"
                  className="ccustomer1"
                />

                <h4 className="sitem-text ccustomertext1">Affordability</h4>
                <p className="sitem-p">
                  A memorable travel experience should be accessible to everyone
                  and anyone. Affordability without compromising quality is our
                  USP! Our strength lies in securing the best deals and helping
                  you save time and expenses.
                </p>
              </div>
            </div>
            <div className="sgrid-item">
              <img
                src="/Media/customer-satisfaction.jpg"
                alt="customer-support"
                className="sservicesimg"
              />
              <div className="sbox-text">
                <img
                  src="/Media/customer.jpg"
                  alt="customersatisfy"
                  className="ccustomer2"
                />

                <h4 className="sitem-text ccustomertext2">
                  Customer - Centric
                </h4>
                <p className="sitem-p">
                  We plan journeys that only satisfy but leave you eager for
                  more. We are ready to go that extra mile in understanding your
                  interests and needs for itineraries matching your unique
                  aspirations.
                </p>
              </div>
            </div>
          </div>
          <h1 className="endingtrust">
            We are passionate about your travel as you are!
          </h1>
        </section>
      </>
    );
        }
// third if for screenwidth from  1440 to maximum

   else {
    return (
      <>
        <section className="sersection">
          <div className="services">
            <div className="service-box">
              <h1 className="services-text">Our Services</h1>
              <span className="services-span">
                We are committed to presenting the best services to our clients
                to enhances their travel experience and equip them with
                unforgettable journeys.
              </span>
            </div>
            <div className="services-grid">
              <div className="grid-item">
                <img
                  src="/Media/Air-Ticket-Consolidation.jpg"
                  alt="airticket-consolidation"
                  className="servicesimg"
                />
                <div className="box-text">
                  <h4 className="item-text">Air Ticket Consolidation</h4>
                  <p className="item-p">
                    We pride ouselves in providing the best offers, affordable
                    flight bookings and seamless experience to both the agents
                    and our valuable clients. Our team maps out the best travel
                    plan - from homebound adventures to international tours.
                  </p>
                </div>
              </div>
              <div className="grid-item">
                <img
                  src="/Media/personalised-map.jpg"
                  alt="personalised-map"
                  className="servicesimg"
                />
                <div className="box-text">
                  <h4 className="item-text">Personalised Travel Packages</h4>
                  <p className="item-p">
                    We guarantee exceptional, tailor-made packages that meet
                    your needs. We understand that each traveller is unique, and
                    thus we design every package in accordance with the client's
                    preference and budget.
                  </p>
                </div>
              </div>
              <div className="grid-item">
                <img
                  src="/Media/Hassle-free.jpg"
                  alt="hasslefree-services"
                  className="servicesimg"
                />
                <div className="box-text">
                  <h4 className="item-text">Hassle-Free Bookings</h4>
                  <p className="item-p">
                    Our efficient team has mastered the art of taking your share
                    of the burden. From securing your tickets to ensuring
                    seamless acommmodation and other travel necessities- a call
                    to agencies to avail best discount offers for your clients!
                  </p>
                </div>
              </div>
              <div className="grid-item">
                <img
                  src="/Media/24-7.jpg"
                  alt="customer-support"
                  className="servicesimg"
                />
                <div className="box-text">
                  <h4 className="item-text">24/7 Customer Support</h4>
                  <p className="item-p">
                    Travel hiccups can occur anytime. Our committed customer
                    support team is available around the clock, ready to provide
                    assistance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sservices">
          <div className="sservice-box">
            <h1 className="sservices-text">Why Choose Us?</h1>
            <span className="sservices-span">
              <b>RNR TravelTech</b> understands your need for extraordinary
              travel experience, our dedication, meticulous planning and
              professional guidance sets us apart.
            </span>
          </div>
          <div className="sservices-grid">
            <div className="sgrid-item">
              <img
                src="/Media/expertise.jpg"
                alt="airticket-consolidation"
                className="sservicesimg"
              />
              <div className="sbox-text">
                <img
                  src="/Media/number1.jpg"
                  alt="expertise"
                  className="ccustomer1"
                />

                <h4 className="sitem-text">Expertise</h4>
                <p className="sitem-p">
                  With decades of experience and a proven industry record, we
                  have developed an in-depth knowledge of destinations, airlines
                  and travel styles.
                </p>
              </div>
            </div>

            <div className="sgrid-item">
              <img
                src="/Media/save-money.jpeg"
                alt="hasslefree-services"
                className="sservicesimg"
              />
              <div className="sbox-text">
                <img
                  src="/Media/cashhand.png"
                  alt="cash"
                  className="ccustomer1"
                />

                <h4 className="sitem-text ccustomertext1">Affordability</h4>
                <p className="sitem-p">
                  A memorable travel experience should be accessible to everyone
                  and anyone. Affordability without compromising quality is our
                  USP! Our strength lies in securing the best deals and helping
                  you save time and expenses.
                </p>
              </div>
            </div>
            <div className="sgrid-item">
              <img
                src="/Media/customer-satisfaction.jpg"
                alt="customer-support"
                className="sservicesimg"
              />
              <div className="sbox-text">
                <img
                  src="/Media/customer.jpg"
                  alt="customersatisfy"
                  className="ccustomer2"
                />

                <h4 className="sitem-text ccustomertext2">
                  Customer - Centric
                </h4>
                <p className="sitem-p">
                  We plan journeys that only satisfy but leave you eager for
                  more. We are ready to go that extra mile in understanding your
                  interests and needs for itineraries matching your unique
                  aspirations.
                </p>
              </div>
            </div>
          </div>
          <h1 className="endingtrust">
            We are passionate about your travel as you are!
          </h1>
        </section>
      </>
    );
  }
}

export default Services;
