import Footer from "../Pages/Footer";
import "../index.css";
import { Link } from "react-router-dom";
import Animatedpage from "../Animatedpage";
import { GiHamburgerMenu } from "react-icons/gi";
import { useState } from "react";
import ScrollToTop from "react-scroll-to-top";

export function ContactUs() {
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  const handleClick = (event) => {
    event.preventDefault();
    setShowMediaIcons(!showMediaIcons);
  };

  const screenWidth = window.innerWidth;
  const breakpoint = 1020;

  if(screenWidth<breakpoint){
    return (
      <>
        <Animatedpage>
        <div className='notification-bars'>
       For more travel deals and details, visit our site <a target="_blank" rel = "noreferrer" href="https://deals.fareclubs.com" className="deals">Fareclubs!!</a>
      
      </div>
          <section className="AIntro">
            <nav className="NIntro">
              <div className="main-nav">
                <div className="navlogo">
                  <Link to="/">
                    <img src="/Media/rnrlogo.png" alt="Logo" />
                  </Link>
                </div>
  
                <div
                  className={
                    showMediaIcons ? "sections mobile-menu-link" : "sections"
                  }
                >
                  <Link to="/">
                    <h5 className="section1 sfist navspan">Home</h5>
                  </Link>
                  <Link to="/About">
                    <h5 className="section1 navspan ">About Us</h5>
                  </Link>
                  <Link to="/Contact">
                    <h5 className="section1 navspan navlast">Contact Us</h5>
                  </Link>
                </div>
                <div
                  className="hamburger-menu"
                  onClick={handleClick}
                >
                  <a href="#">
                    <GiHamburgerMenu />
                  </a>
                </div>
              </div>
            </nav>
          </section>
          <div className="aboutc">
            <div className="contactdiv">
              <h1>Contact Us</h1>
            </div>
          
            <div
              className=" formc bg-card text-card-foreground w-full max-w-lg border-[3px] formc border-[#000000] rounded-lg shadow-lg aboutspecial"
              data-v0-t="card"
            >
              <div className="flex flex-col space-y-1.5 p-2">
                <p className="text-m text-muted-foreground text-center">
                  Fill out the form below and we'll get back to you as soon as
                  possible.
                </p>
              </div>
              <div className="p-6 space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <label
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      for="first-name"
                    >
                      First name
                    </label>
                    <input
                      className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                      id="first-name"
                      placeholder="Enter your first name"
                      required
                    />
                  </div>
                  <div className="space-y-2">
                    <label
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      for="last-name"
                    >
                      Last name
                    </label>
                    <input
                      className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                      id="last-name"
                      placeholder="Enter your last name"
                      required
                    />
                  </div>
                </div>
                <div className="space-y-2">
                  <label
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    for="email"
                  >
                    Email
                  </label>
                  <input
                    required
                    className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                    id="email"
                    placeholder="Enter your email"
                    type="email"
                  />
                </div>
                <div className="space-y-2">
                  <label
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    for="message"
                  >
                    Message
                  </label>
                  <textarea
                    className="flex w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 min-h-[100px]"
                    id="message"
                    placeholder="Enter your message"
                  ></textarea>
                </div>
                <button className="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary hover:bg-primary/90 h-10 px-4 py-2 bg-gradient-to-r from-pink-500 to-purple-500 text-white rounded-full">
                  Send message
                </button>
              </div>
            </div>
          </div>
  
          <Footer />
        </Animatedpage>
        <ScrollToTop smooth top style={{width: "55px",right: "30px",height:"55px",paddingLeft:"13px",backgroundColor:"white",filter:"invert(1)"}}
           />
      </>
    );
  }
  else{
    return (
      <>
        <Animatedpage>
        <div className='notification-bars'>
       For more travel deals and details, visit our site <a target="_blank" rel = "noreferrer" href="https://deals.fareclubs.com" className="deals">Fareclubs!!</a>
      
      </div>
          <section className="AIntro">
            <nav className="NIntro">
              <div className="main-nav">
                <div className="navlogo">
                  <Link to="/">
                    <img src="/Media/rnrlogo.png" alt="Logo" />
                  </Link>
                </div>
  
                <div
                  className={
                    showMediaIcons ? "sections mobile-menu-link" : "sections"
                  }
                >
                  <Link to="/">
                    <h5 className="section1 sfist navspan">Home</h5>
                  </Link>
                  <Link to="/About">
                    <h5 className="section1 navspan ">About Us</h5>
                  </Link>
                  <Link to="/Contact">
                    <h5 className="section1 navspan navlast">Contact Us</h5>
                  </Link>
                </div>
                <div
                  className="hamburger-menu"
                  onClick={handleClick}
                >
                  <a href="#">
                    <GiHamburgerMenu />
                  </a>
                </div>
              </div>
            </nav>
          </section>
          <div className="aboutc">
            <div className="contactdiv">
              <h1>Contact Us</h1>
            </div>
          
            <div
              className=" formc bg-card text-card-foreground w-full max-w-lg border-[3px] formc border-[#000000] rounded-lg shadow-lg aboutspecial " style={{ margin: "10% 0%",marginInline: "auto",}}
              data-v0-t="card"
            >
              <div className="flex flex-col space-y-1.5 p-2">
                <p className="text-m text-muted-foreground text-center">
                  Fill out the form below and we'll get back to you as soon as
                  possible.
                </p>
              </div>
              <div className="p-6 space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <label
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      for="first-name"
                    >
                      First name
                    </label>
                    <input
                      className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                      id="first-name"
                      placeholder="Enter your first name"
                      required
                    />
                  </div>
                  <div className="space-y-2">
                    <label
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      for="last-name"
                    >
                      Last name
                    </label>
                    <input
                      className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                      id="last-name"
                      placeholder="Enter your last name"
                      required
                    />
                  </div>
                </div>
                <div className="space-y-2">
                  <label
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    for="email"
                  >
                    Email
                  </label>
                  <input
                    required
                    className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                    id="email"
                    placeholder="Enter your email"
                    type="email"
                  />
                </div>
                <div className="space-y-2">
                  <label
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    for="message"
                  >
                    Message
                  </label>
                  <textarea
                    className="flex w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 min-h-[100px]"
                    id="message"
                    placeholder="Enter your message"
                  ></textarea>
                </div>
                <button className="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary hover:bg-primary/90 h-10 px-4 py-2 bg-gradient-to-r from-pink-500 to-purple-500 text-white rounded-full">
                  Send message
                </button>
              </div>
            </div>
          </div>
  
          <Footer />
        </Animatedpage>
        <ScrollToTop smooth top style={{width: "55px",right: "30px",height:"55px",paddingLeft:"13px",backgroundColor:"white",filter:"invert(1)"}}
           />
      </>
    );
  }
 
}
