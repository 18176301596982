import Footer from "../Pages/Footer";
// import Sliders from "../Pages/Sliders";
// import Teamslider from "../Pages/Teamslider";
import "../index.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import Animatedpage from "../Animatedpage";
import { GiHamburgerMenu } from "react-icons/gi";
import ScrollToTop from "react-scroll-to-top";

export function About() {
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }
  const handleClick = (event) => {
    event.preventDefault();
    setShowMediaIcons(!showMediaIcons);
  };
  const screenWidth = window.innerWidth;
  const breakpoint = 1020;

  if (screenWidth < breakpoint) {
    return (
      <>
        <Animatedpage>
          {/* AIntro section */}
          <div className='notification-bars'>
       For more travel deals and details, visit our site <a target="_blank" rel = "noreferrer" href="https://deals.fareclubs.com" className="deals">Fareclubs!!</a>
      
      </div>
          <section className="AIntro">
            <nav className="NIntro">
              <div className="main-nav">
                <div className="navlogo">
                  <Link to="/">
                    <img src="/Media/rnrlogo.png" alt="Logo" />
                  </Link>
                </div>

                <div
                  className={
                    showMediaIcons ? "sections mobile-menu-link" : "sections"
                  }
                >
                  <Link to="/">
                    <h5 className="section1  navspan">Home</h5>
                  </Link>
                  <Link to="/About">
                    <h5 className="section1 navspan ssecond" >About Us</h5>
                  </Link>

                  <Link to="/Contact">
                    <h5 className="section1 navspan navlast" >
                      Contact Us
                    </h5>
                  </Link>
                </div>
                <div
                  className="hamburger-menu"
                  onClick={handleClick}
                >
                  <a href="#">
                    <GiHamburgerMenu />
                  </a>
                </div>
              </div>
            </nav>
          </section>
          {/* About section */}

          <div className="about">
            <div className="aboutdiv">
              <h1>About Us</h1>
            </div>
            <div className="apara">
              <p className="afirstp">
                We have been a prominent force in the travel industry for years
                and have established a reputation for excellence. With over
                1000+ cities explored, our bucket list promises to not end here.
                <br></br>
                <br></br>
                Our ultimate goal lies in helping you create memories that span
                overseas.
              </p>
            </div>
          </div>

          {/* Services Section */}
          <section className="mission">
            <div className="mission-box">
              <h1 className="mission-text">Mission & Vision</h1>
            </div>
            <div className="mission-grid">
              <div className="mgrid-item">
                <img
                  src="/Media/Mission.jpg"
                  alt="customer-support"
                  className="missionimg"
                />
                <div className="mbox-text">
                  <h4 className="mitem-text">Mission</h4>
                  <p className="mitem-p">
                    We pride ouselves in providing the best offers, affordable
                    flight bookings and seamless experience to both the agents
                    and our valuable clients. Our team maps out the best travel
                    plan - from homebound adventures to international tours.
                  </p>
                </div>
              </div>
              <div className="mgrid-item">
                <img
                  src="/Media/vision.jpg"
                  alt="customer-support"
                  className="missionimg"
                />
                <div className="mbox-text">
                  <h4 className="mitem-text">Vision</h4>
                  <p className="mitem-p">
                    We pride ouselves in providing the best offers, affordable
                    flight bookings and seamless experience to both the agents
                    and our valuable clients. Our team maps out the best travel
                    plan - from homebound adventures to international tours.
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* <Teamslider /> */}
          <Footer />

          {/* contact section */}
          {modal && (
            <div className="modal">
              <div onClick={toggleModal} className="overlay"></div>

              <div className="modal-content">
                <div className="modalgrid1">
                  <h1>Get in touch with us today!</h1>

                  <form className="action-page">
                    <input
                      type="text"
                      placeholder="Your name.."
                      name="name"
                      required
                    />
                    <input
                      type="email"
                      placeholder="Your Email Id.."
                      name="Email"
                      required
                    />
                    <textarea
                      name="address"
                      placeholder="Your address"
                      required
                    ></textarea>
                    <input type="submit" value="Submit" />
                  </form>
                </div>
                <div className="modalgrid2">
                  <img src="/Media/contact-image.jpg" alt="contactimg" />
                </div>
                <button className="close-modal" onClick={toggleModal}>
                  <img src="/Media/cross.jpg" alt="cross" />
                </button>
              </div>
            </div>
          )}
        </Animatedpage>
        <ScrollToTop smooth top style={{width: "55px",right: "30px",height:"55px",paddingLeft:"13px",backgroundColor:"white",filter:"invert(1)"}}
         />
      </>
    );
  }
   else {
    return (
      <>
        <Animatedpage>
          {/* AIntro section */}
          <div className='notification-bars'>
       For more travel deals and details, visit our site <a target="_blank" rel = "noreferrer" href="https://deals.fareclubs.com" className="deals">Fareclubs!!</a>
      
      </div>
          <section className="AIntro">
            <nav className="NIntro">
              <div className="main-nav">
                <div className="navlogo">
                  <Link to="/">
                    <img src="/Media/rnrlogo.png" alt="Logo" />
                  </Link>
                </div>

                <div
                  className={
                    showMediaIcons ? "sections mobile-menu-link" : "sections"
                  }
                >
                  <Link to="/">
                    <h5 className="section1  navspan">Home</h5>
                  </Link>
                  <Link to="/About">
                    <h5 className="section1 navspan ssecond">About Us</h5>
                  </Link>
                    <h5 className="section1 navspan navlast " onClick={toggleModal}>
                      Contact Us
                    </h5>
                </div>
              </div>
            </nav>
          </section>
          {/* About section */}

          <div className="about">
            <div className="aboutdiv">
              <h1>About Us</h1>
            </div>
            <div className="apara">
              <p className="afirstp">
                We have been a prominent force in the travel industry for years
                and have established a reputation for excellence. With over
                1000+ cities explored, our bucket list promises to not end here.
                <br></br>
                <br></br>
                Our ultimate goal lies in helping you create memories that span
                overseas.
              </p>
            </div>
          </div>

          {/* Services Section */}
          <section className="mission">
            <div className="mission-box">
              <h1 className="mission-text">Mission & Vision</h1>
            </div>
            <div className="mission-grid">
              <div className="mgrid-item">
                <img
                  src="/Media/Mission.jpg"
                  alt="customer-support"
                  className="missionimg"
                />
                <div className="mbox-text">
                  <h4 className="mitem-text">Mission</h4>
                  <p className="mitem-p">
                    We pride ouselves in providing the best offers, affordable
                    flight bookings and seamless experience to both the agents
                    and our valuable clients. Our team maps out the best travel
                    plan - from homebound adventures to international tours.
                  </p>
                </div>
              </div>
              <div className="mgrid-item">
                <img
                  src="/Media/vision.jpg"
                  alt="customer-support"
                  className="missionimg"
                />
                <div className="mbox-text">
                  <h4 className="mitem-text">Vision</h4>
                  <p className="mitem-p">
                    We pride ouselves in providing the best offers, affordable
                    flight bookings and seamless experience to both the agents
                    and our valuable clients. Our team maps out the best travel
                    plan - from homebound adventures to international tours.
                  </p>
                </div>
              </div>
            </div>
          </section>

        

          {/* <Sliders /> */}
          <Footer />

          {/* contact section */}
          {modal && (
            <div className="modal">
              <div onClick={toggleModal} className="overlay"></div>

              <div className="modal-content">
                <div className="modalgrid1">
                  <h1>Get in touch with us today!</h1>

                  <form className="action-page">
                    <input
                      type="text"
                      placeholder="Your name.."
                      name="name"
                      required
                    />
                    <input
                      type="email"
                      placeholder="Your Email Id.."
                      name="Email"
                      required
                    />
                    <textarea
                      name="address"
                      placeholder="Your address"
                      required
                    ></textarea>
                    <input type="submit" value="Submit" />
                  </form>
                </div>
                <div className="modalgrid2">
                  <img src="/Media/contact-image.jpg" alt="contactimg" />
                </div>
                <button className="close-modal" onClick={toggleModal}>
                  <img src="/Media/cross.jpg" alt="cross" />
                </button>
              </div>
            </div>
          )}
        </Animatedpage>
        <ScrollToTop
          smooth
          top
          style={{
            width: "55px",
            right: "30px",
            height: "55px",
            paddingLeft: "13px",
            backgroundColor: "white",
            filter: "invert(1)",
          }}
        />
      </>
    );
  }
}
