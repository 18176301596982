import Footer from "../Pages/Footer";
import "../index.css";
import { useState } from "react";
import Animatedpage from "../Animatedpage";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import ScrollToTop from "react-scroll-to-top";

export function Terms() {
  const [modal, setModal] = useState(false);
  const [showMediaIcons, setShowMediaIcons] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  const handleClick = (event) => {
    event.preventDefault();
    setShowMediaIcons(!showMediaIcons);
  };

  const screenWidth = window.innerWidth;
  const breakpoint = 1020;

  if (screenWidth < breakpoint) {
    return (
      <>
        <Animatedpage>
          <div className="notification-bars">
            For more travel deals and details, visit our site{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://deals.fareclubs.com"
              className="deals"
            >
              Fareclubs!!
            </a>
          </div>
          <section className="AIntro">
            <nav className="NIntro">
              <div className="main-nav">
                <div className="navlogo">
                  <Link to="/">
                    <img src="/Media/rnrlogo.png" alt="Logo" />
                  </Link>
                </div>

                <div
                  className={
                    showMediaIcons ? "sections mobile-menu-link" : "sections"
                  }
                >
                  <Link to="/">
                    <h5 className="section1  navspan">Home</h5>
                  </Link>
                  <Link to="/About">
                    <h5 className="section1 navspan ssecond">About Us</h5>
                  </Link>

                  <Link to="/Contact">
                    <h5 className="section1 navspan navlast ">Contact Us</h5>
                  </Link>
                </div>
                <div className="hamburger-menu" onClick={handleClick}>
                  <a href="#">
                    <GiHamburgerMenu />
                  </a>
                </div>
              </div>
            </nav>
          </section>
          <div className="privacy1">
            <div className="termsdiv"></div>
            <div className="ppara1">
              <p className="pfirstp">
                By hiring 'RNR TravelTech' as your travel partner, you agree to
                abide by the terms and conditions of the services mentioned on
                this site. Upon registering with us as your tour operatore, you
                will be considered to have reviewed all the travel information.
                Secrets, patents, licencsing and others.
              </p>

              <p className="psecondp">
                The chargeable payments required are processed through secured
                channels. You'll be liable for the taxes and fees associated
                with your bookings and reservations.
              </p>
              <h1>
                <b>We have no hidden charges.</b>
              </h1>
              <h1>
                <b>
                  Refunds and cancellations will be processed depending on your
                  travel package and our criteria.
                </b>
              </h1>
            </div>
          </div>
          <Footer />
          {/* contact section */}
          {modal && (
            <div className="modal">
              <div onClick={toggleModal} className="overlay"></div>

              <div className="modal-content">
                <div className="modalgrid1">
                  <h1>Get in touch with us today!</h1>

                  <form className="action-page">
                    <input
                      type="text"
                      placeholder="Your name.."
                      name="name"
                      required
                    />
                    <input
                      type="email"
                      placeholder="Your Email Id.."
                      name="Email"
                      required
                    />
                    <textarea
                      name="address"
                      placeholder="Your address"
                      required
                    ></textarea>
                    <input type="submit" value="Submit" />
                  </form>
                </div>
                <div className="modalgrid2">
                  <img src="/Media/contact-image.jpg" alt="contactimg" />
                </div>
                <button className="close-modal" onClick={toggleModal}>
                  <img src="/Media/cross.jpg" alt="cross" />
                </button>
              </div>
            </div>
          )}
        </Animatedpage>
        <ScrollToTop
          smooth
          top
          style={{
            width: "55px",
            right: "30px",
            height: "55px",
            paddingLeft: "13px",
            backgroundColor: "white",
            filter: "invert(1)",
          }}
        />
      </>
    );
  } else {
    return (
      <>
        <Animatedpage>
          <div className="notification-bars">
            For more travel deals and details, visit our site{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://deals.fareclubs.com"
              className="deals"
            >
              Fareclubs!!
            </a>
          </div>
          <section className="AIntro">
            <div className="main-nav">
              <div className="navlogo">
                <Link to="/">
                  <img src="/Media/rnrlogo.png" alt="Logo" />
                </Link>
              </div>

              <div
                className={
                  showMediaIcons ? "sections mobile-menu-link" : "sections"
                }
              >
                <Link to="/">
                  <h5 className="section1  navspan">Home</h5>
                </Link>
                <Link to="/About">
                  <h5 className="section1 navspan ssecond">About Us</h5>
                </Link>

                <h5 className="section1 navspan navlast " onClick={toggleModal}>
                  Contact Us
                </h5>
              </div>
            </div>
          </section>
          <div className="privacy">
            <div className="termsdiv">
              <h1>Terms of Service</h1>
            </div>
            <div className="ppara">
              <p className="pfirstp">
                By hiring 'RNR TravelTech' as your travel partner, you agree to
                abide by the terms and conditions of the services mentioned on
                this site. Upon registering with us as your tour operatore, you
                will be considered to have reviewed all the travel information.
                Secrets, patents, licencsing and others.
              </p>

              <p className="psecondp">
                The chargeable payments required are processed through secured
                channels. You'll be liable for the taxes and fees associated
                with your bookings and reservations.
              </p>
              <h1>We have no hidden charges.</h1>
              <h1>
                Refunds and cancellations will be processed depending on your
                travel package and our criteria.{" "}
              </h1>
            </div>
          </div>
          <Footer />
          {/* contact section */}
          {modal && (
            <div className="modal">
              <div onClick={toggleModal} className="overlay"></div>

              <div className="modal-content">
                <div className="modalgrid1">
                  <h1>Get in touch with us today!</h1>

                  <form className="action-page">
                    <input
                      type="text"
                      placeholder="Your name.."
                      name="name"
                      required
                    />
                    <input
                      type="email"
                      placeholder="Your Email Id.."
                      name="Email"
                      required
                    />
                    <textarea
                      name="address"
                      placeholder="Your address"
                      required
                    ></textarea>
                    <input type="submit" value="Submit" />
                  </form>
                </div>
                <div className="modalgrid2">
                  <img src="/Media/contact-image.jpg" alt="contactimg" />
                </div>
                <button className="close-modal" onClick={toggleModal}>
                  <img src="/Media/cross.jpg" alt="cross" />
                </button>
              </div>
            </div>
          )}
        </Animatedpage>
        <ScrollToTop
          smooth
          top
          style={{
            width: "55px",
            right: "30px",
            height: "55px",
            paddingLeft: "13px",
            backgroundColor: "white",
            filter: "invert(1)",
          }}
        />
      </>
    );
  }
}
