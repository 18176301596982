import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../index.css";
function ESliders() {
  const data = [
    {
      img: "/Media/Germany.jpg",
      name: "Germany",
      description:
        "Germany, located in the heart of Europe, is a country renowned for its rich history, stunning landscapes and vibrant culture. With a diverse range of attractions, Germany offers something for every traveler.",
    },
    {
      img: "/Media/brazil.jpg",
      name: "Brazil",
      description:
        "Welcome to Brazil, where culture, nature, and adventureconverge in a syjmphony of experiences. From the rhythmic beats of Rio de Janeiro to the lush depths of the AmazonRainforest, Brazil is a treasure trove of diversity waiting to be explored.",
    },
    {
      img: "/Media/italy.jpg",
      name: "Italy",
      description:
        "Italy, a country synonymous with art, history and gastronomoy, is a captivating destination in the heart of Europe, landscapes, from the romantic canals of Venice to the ancient ruins of Rome, offer travelers a unique blend of culture and natural beauty.",
    },
    {
      img: "/Media/iceland.jpg",
      name: "Iceland",
      description:
        "Iceland, a land of dramatic beauty, is a unique destination in the North Atlantic. In this concise guide, we'll intorduce you to its captivating natural wonders and cultural treasures.",
    },
    {
      img: "/Media/egypt.jpg",
      name: "Egypt",
      description:
        "Egypt, a land of ancient wonders and modern marvels, beckons travelers ith its timeless allure. situtated in the northeastern corner of Africa, Egupt boasts a rich history that spans millennia, captivating explorers and historians alike.",
    },
    {
      img: "/Media/hongkong.jpg",
      name: "Hong Kong",
      description:
        "Hong Kong, a vibrant metropolis nestled on the southern coast or China, is a dynamic fusion of tradition and modernity. This bustling scity is renowned for its iconic skyline, bustling harbor and rich cultural heritage.",
    },
    {
      img: "/Media/france.jpg",
      name: "France",
      description:
        "France, a country synonymous with romance, culturema dn exquisite cuisine, is a captiviating destination that never fails to enthrall visitors.",
    },
  ];

  const settings = {
    infinite: false,
    dots: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    accessibility: true,
    arrows: true,
    responsive: [
      // {
      //   breakpoint: 1024,
      //   settings: {
      //     slidesToShow: 3,
      //     slidesToScroll: 3,
      //     infinite: true,
      //     dots: true,
      //   },
      // },
      // {
      //   breakpoint: 600,
      //   settings: {
      //     slidesToShow: 2,
      //     slidesToScroll: 2,
      //     initialSlide: 2,
      //   },
      // },
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          speed:300,
          dots:true,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          speed:300,
          dots:true,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          speed:500,
          dots:true,
        },
      },
      {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: true,
            speed:400,

          },
        },
      {
          breakpoint: 750,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            speed:400,
          },
        },
    ],
  };
  return (
    <>
      <section className="w-3/4 m-auto">
        <div className="mt-15 ">
          <Slider {...settings}>
            {data.map((d) => (
              <div key={d.name} className="tservices-grid ">
                <div className="tgrid-item  rounded-xl">
                  <img
                    src={d.img}
                    alt="airticket-consolidation"
                    className=" rounded-t-lg tservicesimg"
                  />
                  <div className="tbox-text">
                    <div className="overlaybox flex item-center">
                      <img
                        src="/Media/star.png"
                        alt="ratings"
                        className="mr-2"
                      />
                      <span>4.5</span>
                    </div>
                    <h4 className="titem-text">{d.name}</h4>
                    <p className="titem-p">{d.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </>
  );
}

export default ESliders;
