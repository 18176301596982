import Footer from "../Pages/Footer";
import "../index.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import Animatedpage from "../Animatedpage";
import { GiHamburgerMenu } from "react-icons/gi";
import ScrollToTop from "react-scroll-to-top";

export function Privacy() {
  const [modal, setModal] = useState(false);
  const [showMediaIcons, setShowMediaIcons] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }
  const handleClick = (event) => {
    event.preventDefault();
    setShowMediaIcons(!showMediaIcons);
  };
  const screenWidth = window.innerWidth;
  const breakpoint = 1020;

  if (screenWidth < breakpoint) {
    return (
      <>
        <Animatedpage>
        <div className='notification-bars'>
       For more travel deals and details, visit our site <a target="_blank" rel = "noreferrer" href="https://deals.fareclubs.com" className="deals">Fareclubs!!</a>
      
      </div>
          <section className="AIntro">
            <nav className="NIntro">
              <div className="main-nav">
                <div className="navlogo">
                  <Link to="/">
                    <img src="/Media/rnrlogo.png" alt="Logo" />
                  </Link>
                </div>

                <div
                  className={
                    showMediaIcons ? "sections mobile-menu-link" : "sections"
                  }
                >
                  <Link to="/">
                    <h5 className="section1  navspan">Home</h5>
                  </Link>
                  <Link to="/About">
                    <h5 className="section1 navspan ssecond">About Us</h5>
                  </Link>

                  <Link to="/Contact">
                    <h5 className="section1 navspan navlast ">
                      Contact Us
                    </h5>
                  </Link>
                </div>
                <div
                  id="hamburger-event"
                  className="hamburger-menu"
                  onClick={handleClick}
                >
                  <a href="#">
                    <GiHamburgerMenu />
                  </a>
                </div>
              </div>
            </nav>
          </section>
          <section className="privacy">
            <div className="privacybg">
              <div className="privacydiv">
                <h1>Privacy Policy</h1>
              </div>
            </div>
            <div className="ppara">
              <p className="pfirstp">
                We have registered IP(Intellectual Property) products and
                services safeguarded under the following laws: Trademarks,
                copyright, Trade Secrets, patents, licencsing and others.
              </p>

              <p className="psecondp">
                Therefore any unauthorised use of our porducts, services or
                confidential data stored on this site will deemed to be
                unlawful. The user is expected not top copy, sell or design
                materials and services in any form.
              </p>
              <p className="pthirdp">
                While, we have no ownership of any contentm document, trademarks
                or copyrights of the third-party listed on this site. The
                properietorship of their software and privacy lies in their
                respective hands. We are licensed to hold the authorization of
                'RNR TravelTech' only, including its software and
                documentation recorded on this site. No user should be able to
                hack, use or Crack software code unless they have direct
                authority over the license agreement.
              </p>
            </div>
            <Footer />
            {/* contact section */}
            {modal && (
              <div className="modal">
                <div onClick={toggleModal} className="overlay"></div>

                <div className="modal-content">
                  <div className="modalgrid1">
                    <h1>Get in touch with us today!</h1>

                    <form className="action-page">
                      <input
                        type="text"
                        placeholder="Your name.."
                        name="name"
                        required
                      />
                      <input
                        type="email"
                        placeholder="Your Email Id.."
                        name="Email"
                        required
                      />
                      <textarea
                        name="address"
                        placeholder="Your address"
                        required
                      ></textarea>
                      <input type="submit" value="Submit" />
                    </form>
                  </div>
                  <div className="modalgrid2">
                    <img src="/Media/contact-image.jpg" alt="contactimg" />
                  </div>
                  <button className="close-modal" onClick={toggleModal}>
                    <img src="/Media/cross.jpg" alt="cross" />
                  </button>
                </div>
              </div>
            )}
          </section>
        </Animatedpage>
        <ScrollToTop
          smooth
          top
          style={{
            width: "55px",
            right: "30px",
            height: "55px",
            paddingLeft: "13px",
            backgroundColor: "white",
            filter: "invert(1)",
          }}
        />
      </>
    );
  } 
  else {
    return (
      <>
        <Animatedpage>
        <div className='notification-bars'>
       For more travel deals and details, visit our site <a target="_blank" rel = "noreferrer" href="https://deals.fareclubs.com" className="deals">Fareclubs!!</a>
      
      </div>
          <section className="AIntro">
            <nav className="NIntro">
              <div className="main-nav">
                <div className="navlogo">
                  <Link to="/">
                    <img src="/Media/rnrlogo.png" alt="Logo" />
                  </Link>
                </div>

                <div
                  className={
                    showMediaIcons ? "sections mobile-menu-link" : "sections"
                  }
                >
                  <Link to="/">
                    <h5 className="section1  navspan">Home</h5>
                  </Link>
                  <Link to="/About">
                    <h5 className="section1 navspan ssecond">About Us</h5>
                  </Link>

                  <h5
                    className="section1 navspan navlast "
                    onClick={toggleModal}
                  >
                    Contact Us
                  </h5>
                </div>
              </div>
            </nav>
          </section>
          <section className="privacy">
            <div className="privacybg">
              <div className="privacydiv">
                <h1>Privacy Policy</h1>
              </div>
            </div>
            <div className="ppara">
              <p className="pfirstp">
                We have registered IP(Intellectual Property) products and
                services safeguarded under the following laws: Trademarks,
                copyright, Trade Secrets, patents, licencsing and others.
              </p>

              <p className="psecondp">
                Therefore any unauthorised use of our porducts, services or
                confidential data stored on this site will deemed to be
                unlawful. The user is expected not top copy, sell or design
                materials and services in any form.
              </p>
              <p className="pthirdp">
                While, we have no ownership of any contentm document, trademarks
                or copyrights of the third-party listed on this site. The
                properietorship of their software and privacy lies in their
                respective hands. We are licensed to hold the authorization of
                'RNR TravelTech' only, including its software and
                documentation recorded on this site. No user should be able to
                hack, use or Crack software code unless they have direct
                authority over the license agreement.
              </p>
            </div>
            <Footer />
            {/* contact section */}
            {modal && (
              <div className="modal">
                <div onClick={toggleModal} className="overlay"></div>

                <div className="modal-content">
                  <div className="modalgrid1">
                    <h1>Get in touch with us today!</h1>

                    <form className="action-page">
                      <input
                        type="text"
                        placeholder="Your name.."
                        name="name"
                        required
                      />
                      <input
                        type="email"
                        placeholder="Your Email Id.."
                        name="Email"
                        required
                      />
                      <textarea
                        name="address"
                        placeholder="Your address"
                        required
                      ></textarea>
                      <input type="submit" value="Submit" />
                    </form>
                  </div>
                  <div className="modalgrid2">
                    <img src="/Media/contact-image.jpg" alt="contactimg" />
                  </div>
                  <button className="close-modal" onClick={toggleModal}>
                    <img src="/Media/cross.jpg" alt="cross" />
                  </button>
                </div>
              </div>
            )}
          </section>
        </Animatedpage>
        <ScrollToTop
          smooth
          top
          style={{
            width: "55px",
            right: "30px",
            height: "55px",
            paddingLeft: "13px",
            backgroundColor: "white",
            filter: "invert(1)",
          }}
        />
      </>
    );
  }
}
