import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
function Footer() {
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }
  const screenWidth = window.innerWidth;
  const breakpointWidth = 1020;

  if (screenWidth < breakpointWidth) {
    return (
      <>
        <section className="footer-section">
          <footer className="section-p1">
            <div className="maindiv">
              <div className="firstdiv">
                <Link to="/">
                  <img src="/Media/rnrlogo.png" alt="logo" />
                </Link>
                <p>
                  We are a long-standing travel force, known for excellence.
                  With 1000+ cities explored, our bucket list keeps growing. Our
                  ultimate aim: crafting overseas memories with you.
                </p>
              </div>
              <div className="secondmaindiv">
                <div className="seconddiv">
                  <div className="griditem">
                    <h3>About RNR TravelTech</h3>
                    <Link to="/About">
                      <span className="gridspan">• About Us</span>
                    </Link>
                    <Link to="/Contact">
                      <span className="gridspan">• Contact Us</span>
                    </Link>
                  </div>
                  <div className="griditem">
                    <h3>Legal</h3>
                    <Link to="/Privacy">
                      <span className="gridspan">• Privacy Policy</span>
                    </Link>
                    <Link to="/Terms">
                      <span className="gridspan">• Terms of Service</span>
                    </Link>
                  </div>
                  <div className="griditem">
                    <h3>Contact</h3>
                    <span className="gridspan">info@rnrtraveltech.com</span>
                    <span className="gridspan">+91-1245180900</span>
                  </div>
                </div>
                <div className="thirddiv">
                  <div className="thirddivgrid1">
                    <span>
                      Follow us on social media platforms for the latest
                      updates, tips and deals.
                    </span>
                  </div>
                  <div className="thirddivgrid2">
                    <img
                      src="/Media/ig.jpg"
                      alt="footerimages"
                      className="footerimage"
                    />
                    <img
                      src="/Media/x.png"
                      alt="footerimages"
                      className="footerimage"
                    />
                    <img
                      src="/Media/fb.jpg"
                      alt="footerimages"
                      className="footerimage"
                    />
                  </div>
                  <span className="lastspan">
                    Copyright ©2024 RNR TravelTech. All rights reserved.
                  </span>
                </div>
              </div>
            </div>
          </footer>
        </section>
        {/* contact section */}
        {modal && (
          <div className="modal">
            <div onClick={toggleModal} className="overlay"></div>

            <div className="modal-content">
              <div className="modalgrid1">
                <h1>Get in touch with us today!</h1>

                <form className="action-page">
                  <input
                    type="text"
                    placeholder="Your name.."
                    name="name"
                    required
                  />
                  <input
                    type="email"
                    placeholder="Your Email Id.."
                    name="Email"
                    required
                  />
                  <textarea
                    name="address"
                    placeholder="Your address"
                    required
                  ></textarea>
                  <input type="submit" value="Submit" />
                </form>
              </div>
              <div className="modalgrid2">
                <img src="/Media/contact-image.jpg" alt="contactimg" />
              </div>
              <button className="close-modal" onClick={toggleModal}>
                <img src="/Media/cross.jpg" alt="cross" />
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
   else {
    return (
      <>
        <section className="footer-section">
          <footer className="section-p1">
            <div className="maindiv">
              <div className="firstdiv">
                <Link to="/">
                  <img src="/Media/rnrlogo.png" alt="logo" />
                </Link>
                <p>
                  We are a long-standing travel force, known for excellence.
                  With 1000+ cities explored, our bucket list keeps growing. Our
                  ultimate aim: crafting overseas memories with you.
                </p>
              </div>
              <div className="secondmaindiv">
                <div className="seconddiv">
                  <div className="griditem">
                    <h3>About RNR TravelTech</h3>
                    <Link to="/About">
                      <span className="gridspan">• About Us</span>
                    </Link>
                    <span className="gridspan" onClick={toggleModal}>
                      • Contact Us
                    </span>
                  </div>
                  <div className="griditem">
                    <h3>Legal</h3>
                    <Link to="/Privacy">
                      <span className="gridspan">• Privacy Policy</span>
                    </Link>
                    <Link to="/Terms">
                      <span className="gridspan">• Terms of Service</span>
                    </Link>
                  </div>
                  <div className="griditem">
                    <h3>Contact</h3>
                    <span className="gridspan">info@rnrtraveltech.com</span>
                    <span className="gridspan">+91-1245180900</span>
                  </div>
                </div>
                <div className="thirddiv">
                  <span>
                    Follow us on social media platforms for the latest updates,
                    tips and deals.
                  </span>
             
                  <img
                    src="/Media/ig.jpg"
                    alt="footerimages"
                    className="footerimage"
                  />
                  <img
                    src="/Media/x.png"
                    alt="footerimages"
                    className="footerimage"
                  />
                  <img
                    src="/Media/fb.jpg"
                    alt="footerimages"
                    className="footerimage"
                  />
                </div>
                <span className="lastspan">
                  Copyright ©2024 RNR TravelTech. All rights reserved.
                </span>
              </div>
            </div>
          </footer>
        </section>
        {/* contact section */}
        {modal && (
          <div className="modal">
            <div onClick={toggleModal} className="overlay"></div>

            <div className="modal-content">
              <div className="modalgrid1">
                <h1>Get in touch with us today!</h1>

                <form className="action-page">
                  <input
                    type="text"
                    placeholder="Your name.."
                    name="name"
                    required
                  />
                  <input
                    type="email"
                    placeholder="Your Email Id.."
                    name="Email"
                    required
                  />
                  <textarea
                    name="address"
                    placeholder="Your address"
                    required
                  ></textarea>
                  <input type="submit" value="Submit" />
                </form>
              </div>
              <div className="modalgrid2">
                <img src="/Media/contact-image.jpg" alt="contactimg" />
              </div>
              <button className="close-modal" onClick={toggleModal}>
                <img src="/Media/cross.jpg" alt="cross" />
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Footer;
