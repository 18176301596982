import "../index.css";
import React, { useEffect } from "react";
import Intro from "../Pages/Intro";
import Services from "../Pages/Services";
// import Testimonials from "../Pages/Testimonials";
import Contact from "../Pages/ContactForm";
import Footer from "../Pages/Footer";
import { Explore } from "../Pages/Explore";
import Animatedpage from "../Animatedpage";


export function Home() {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowDown") {
        window.scrollBy(0, 100);
        event.preventDefault();
      }
    };
    const handleyKeyUp = (event) => {
      if (event.key === "ArrowUp") {
        window.scrollBy(0, -100);
        event.preventDefault();
      }
    };

    document.addEventListener("keyup", handleyKeyUp);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.addEventListener("keyup", handleyKeyUp);
    };
  }, []);

  return (
    <>
      <Animatedpage>
        <Intro />
        <Services />
        <Explore />
        {/* <Testimonials /> */}
        <Contact />
        <Footer />
      </Animatedpage>

      </>
  );
}
