import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import ScrollToTop from "react-scroll-to-top";



function Intro() {
  const [showMediaIcons, setShowMediaIcons] = useState(false);

  const scrollToContact = () => {
    const contactSection = document.getElementById("contact");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    setShowMediaIcons(!showMediaIcons);
  };

  const screenWidth = window.innerWidth;
  const breakpoint = 1020;

  if (screenWidth < breakpoint) {
    return (
      <>
      <div className='notification-bars'>
       For more travel deals and details, visit our site <a target="_blank" rel = "noreferrer" href="https://deals.fareclubs.com" className="deals">Fareclubs!!</a>
      
      </div>
      <div className="Intro">
        <nav className="NIntro">
          <div className="main-nav">
            <div className="navlogo">
              <img src="/Media/rnrlogo.png" alt="Logo" />
            </div>

            <div
              className={
                showMediaIcons ? "sections mobile-menu-link" : "sections"
              }
            >
              <Link to="/">
                <h5 className="section1 sfist navspan">Home</h5>
              </Link>
              <Link to="/About">
                <h5 className="section1 navspan ">About Us</h5>
              </Link>
              <Link to="/Contact">
                <h5 className="section1 navspan navlast">Contact Us</h5>
              </Link>
            </div>
            <div
              className="hamburger-menu"
              id="hamburger-event"
              onClick={handleClick}
            >
              <a href="#">
                <GiHamburgerMenu />
              </a>
            </div>
          </div>
        </nav>
        <div className="image-container">
          <img
            src="/Media/Banner_Images.jpg"
            alt="firstbanner"
            class="first-banner"
          />
          <div className="banner-text">
            <h1 data-text="RNR Welcomes You">RNR welcomes you</h1>
            <p class="watap">
              <b>RNR TravelTech</b> is your most trusted Travel Company. It believes crafting a
              successful travel strategy to turn your vacation dreams into
              reality.
            </p>

            <button type="button" className="explore" onClick={scrollToContact}>
              <span>Contact Us</span>
              <div>
                <img
                  src="/Media/arrows.svg"
                  alt="Next page"
                  className="white-arrow"
                />
              </div>
            </button>
          </div>
        </div>
        <ScrollToTop
          smooth
          top
          style={{
            width: "55px",
            right: "30px",
            height: "55px",
            paddingLeft: "13px",
            backgroundColor: "white",
            filter: "invert(1)",
          }}
        />
      </div>
      </>
    );
  } else {
    return (
      <>
    
      <div className='notification-bar'>
       For more travel deals and details, visit our site <a target="_blank" rel = "noreferrer" href="https://deals.fareclubs.com" className="deals">Fareclubs!!</a>
      </div>
      <div className="Intro">
        <nav className="NIntro">
          <div className="main-nav">
            <div className="navlogo">
              <img src="/Media/rnrlogo.png" alt="Logo" />
            </div>

            <div
              className={
                showMediaIcons ? "sections mobile-menu-link" : "sections"
              }
            >
              <Link to="/">
                <h5 className="section1 sfist navspan">Home</h5>
              </Link>
              <Link to="/About">
                <h5 className="section1 navspan ">About Us</h5>
              </Link>
              <h5
                className="section1 navspan navlast"
                onClick={scrollToContact}
              >
                Contact Us
              </h5>
            </div>
          </div>
        </nav>
        <div className="image-container">
          <img
            src="/Media/Banner_Images.jpg"
            alt="firstbanner"
            class="first-banner"
          />
          <div className="banner-text">
            <h1>RNR welcomes you</h1>
            <p class="watap">
              <b>RNR TravelTech</b> is your most trusted Travel Company. It believes crafting a
              successful travel strategy to turn your vacation dreams into
              reality.
            </p>

            <button type="button" className="explore" onClick={scrollToContact}>
              <span>Contact Us</span>
              <div>
                <img
                  src="/Media/arrows.svg"
                  alt="Next page"
                  className="white-arrow"
                />
              </div>
            </button>
           
          </div>
        </div>
        <ScrollToTop
          smooth
          top
          style={{
            width: "55px",
            right: "30px",
            height: "55px",
            paddingLeft: "13px",
            backgroundColor: "white",
            filter: "invert(1)",
          }}
        />
      </div>
      </>
    );
  }
}

export default Intro;
