import "./index.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./Routes/Home.js";
import { Privacy } from "./Routes/Privacypolicy.js";
import { About } from "./Routes/About.js";
import { ContactUs } from "./Routes/ContactUs.js";
import { Terms } from "./Routes/Terms.js";
import ScrollToTop from "./Routes/ScrollToTop.js";


function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Privacy" element={<Privacy />} />
          <Route path="/Contact" element={<ContactUs />} />
          <Route path="/Terms" element={<Terms />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
