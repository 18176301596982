import React, { useState } from "react";
import Esliders from "./Esliders";
export function Explore() {
  const content1 = (
    <>
      <div className="tservice-box">
        <span className="tservices-span">
          Desire for navigation should know no bounds. Our destination guide
          will help you capture the most captivating cities, rich cultural
          heritages and scenic vistas.
        </span>
    
      </div>
      
      <Esliders />
    </>
  );

  const content2 = (
    <>
      <div className="stservice-box">
        <span className="tservices-span">
          Our expert tips cover all the aspects of the industry in order to
          provide a flawless travel experience. Check out our blog fot he latest
          travel tips and hacks.
        </span>
      </div>
      <img src="/Media/uploadicon.png" alt="upload" className="uploadimage" />

      <div className="Travelgrid">
        <div className="travelitem1">
          <img src="/Media/trekking-pic.jpeg" alt="trekking" />
        </div>

        <div className="travelitem2">
          <div className="travelgrid1">
            <img
              src="/Media/wanderlust.jpg"
              alt="wanderlust"
              className="gridimage"
            />
            <div className="travelgrid2">
              <span className="">Wanderlust Wisdom: Essential Travel Tips</span>
              <span>27 sept, 2023 • 9 min Read</span>
              <p>
                Unlock a world of travel knowledge! Explore our travel tips blog
                for expert advice on packing, budgetingm safetym and making the
                most of your journeys.
              </p>
            </div>
            <img src="/Media/upload.png" alt="upload" className="uploadimg" />
          </div>
          <div className="travelgrid1">
            <img src="/Media/cliff.jpeg" alt="cliff" className="gridimage" />
            <div className="travelgrid2">
              <span className="utravel1">
                Globe-Trotter's Guide: Proven Travel Tips
              </span>
              <span className="utravel2">27 sept, 2023 • 9 min Read</span>
              <p className="utravel3">
                Navigate the globe like a pro! Our travel tips blog is your
                passport to smarter, more enjoyable journeys.
              </p>
            </div>
            <img src="/Media/upload.png" alt="upload" className="uploadimg" />
          </div>
          <div className="travelgrid1">
            <img src="/Media/plane.jpeg" alt="plane" className="gridimage" />
            <div className="travelgrid2">
              <span>Jetsetter's Journal: Travel Tips Unveiled</span>
              <span>27 sept, 2023 • 9 min Read</span>
              <p className="tp">
                Uncover travel gems! Our blog shares savvy tips on everything
                from flight booking tricks to clutural etiquette. Elevate your
                adventures with expert insider secrets.
              </p>
            </div>
            <img src="/Media/upload.png" alt="upload" className="uploadimg" />
          </div>
        </div>
      </div>
    </>
  );

  const [currentContent, setCurrentContent] = useState(content1);
  const handleButtonClick = (content) => {
    setCurrentContent(content);
  };
  return (
    <>
      <section className="Explore-More">
        <div className="exploring">
          <h1>Explore More</h1>
          <p>Allow us to guide you throughout the journey of discovery.</p>
        </div>
        <button
          className="explorebtn1"
          onClick={() => handleButtonClick(content1)}
        >
          Destination Guide
        </button>
        <button
          className="explorebtn2"
          onClick={() => handleButtonClick(content2)}
        >
          Travel Tips
        </button>
      </section>
      <section className="tservices">{currentContent}</section>
    </>
  );
}
